import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import createRelatedResources from "src/components/helpers/createRelatedResources";

import { IndividualResourceCard } from "src/components/solutions/Resources";
import { sampleSize } from "src/utils";
import { ShareFollowBox } from "../commonComponents/shareFollowBox";

const SideBar = ({ location, locale = 'en' }) => {
  const data = useStaticQuery(graphql`
  {
    USRecent: allSitePage(
      filter: {componentChunkName: {eq: "component---src-templates-common-resources-template-js"}, context: {locale: {eq: "en"}}}
    ) {
      group(field: context___content_type, limit: 3) {
        nodes {
          ...resourceCards
        }
      }
    }
    UKRecent: allSitePage(
      filter: {componentChunkName: {eq: "component---src-templates-common-resources-template-js"}, context: {locale: {eq: "en-gb"}}}
    ) {
      group(field: context___content_type, limit: 3) {
        nodes {
          ...resourceCards
        }
      }
    }
  }
  `);

  const [posts, setPosts] = React.useState(null);

  React.useEffect(() => {
    let mostRecentPost = [];
    let recentData = locale === 'en' ? data?.USRecent : data?.UKRecent
    // Because of the `group` it's necessary to remap the data to fit `createRelatedResources`
    recentData?.group?.forEach((group) => {
      group.nodes.forEach((node) => mostRecentPost.push(node));
    });

    if (location) {
      mostRecentPost = mostRecentPost.filter(
        (item) => item.path !== location.pathname
      );
    }

    const mostRecentPostsResources = createRelatedResources(mostRecentPost, {
      hasDescription: true,
    });

    const samplePosts = sampleSize(mostRecentPostsResources, 3).sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });

    setPosts(samplePosts);
  }, []);

  return (
    <aside className="side-bar-wrapper">
      <div className="mb-4">
        <ShareFollowBox />
      </div>
      <h3 className="title is-uppercase pl-3 title-4">Recent</h3>
      <div className="columns is-multiline">
        {posts &&
          posts.map((card) => (
            <IndividualResourceCard
              data={card}
              className="column is-12"
              key={card.title}
            />
          ))}
      </div>
    </aside>
  );
};

export default SideBar;